.og-color-sef {
    color: #123d5a;
}
.og-bc-sef {
    background-color: #123d5a;
    color: white;
    font-size: 53.271%;
    padding: .46729rem;
}
.og-color-standvirtual {
    color: #0c75c8;
}
.og-bc-standvirtual {
    background-color: #0c75c8;
    color: white;
    font-size: 80%;
    padding: .2rem;
    border-radius: 2px;
}
.og-color-custojusto {
    color: #e56d39;
}
.og-color-custojusto-alt {
    color: #999999;
}