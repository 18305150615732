$font-family: "ogfont" !default;
$font-path: "../font/webfonts" !default;
$font-code: "bzplo8" !default;

@font-face {
    font-family: "#{$font-family}";
    src: url("#{$font-path}/#{$font-family}.eot?#{$font-code}");
    src: url("#{$font-path}/#{$font-family}.eot?#{$font-code}#iefix") format("embedded-opentype"),
         url("#{$font-path}/#{$font-family}.ttf?#{$font-code}") format("truetype"),
         url("#{$font-path}/#{$font-family}.woff?#{$font-code}") format("woff"),
         url("#{$font-path}/#{$font-family}.svg?#{$font-code}##{$font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.ogf {
    font-family: "#{$font-family}" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.og-og:before {
    content: "\e901";
}
.og-og-alt:before {
    content: "\e902";
}
.og-shopify:before {
    content: "\e900";
}
.og-standvirtual:before {
    content: "\e903";
}
.og-sef:before {
    content: "\e904";
}
.og-agt:before {
    content: "\e907";
}
.og-agt-alt:before {
    content: "\e906";
}
.og-at:before {
    content: "\e905";
}
.og-at-alt:before {
    content: "\e908";
}
.og-gt-motive:before {
    content: "\e90a";
}
.og-gt-motive-alt:before {
    content: "\e909";
}
.og-officegest:before {
    content: "\e90e";
}
.og-myallocator:before {
    content: "\e90c";
}
.og-myallocator-alt:before {
    content: "\e90d";
}
.og-standvirtual-alt:before {
    content: "\e90b";
}
.og-shopify-alt:before {
    content: "\e902"; 
}
.og-custojusto:before {
    content: "\e90f"; 
}
.og-custojusto-alt:before {
    content: "\e910"; 
}

@import "og-color";