.fa-availability-red {
	background-image: url("data:image/gif;base64,R0lGODlhGAAYAPcAAAAAAJoAAJgBAZ0AAKEAAKADA6MEBKQAAKcFBaYKCqkAAKsEBK0AAK4DA6QUFKwREa0VFbIAALUAALcFBbkAALgHB70AALIREbkREbofH6YgIK8iIrElJb4hIb0mJrs2Nro5Ob87O7lTU8IAAMEBAcYAAMkAAM0AANEAANMCAtUAANkAAN0AAMIsLMM8PMU8PM89PeEAAOQAAOkAAO4AAOUUFPAAAPQAAPcBAfkAAPgBAf0AAP4BAf4CAv4DA/4EBP8FBf8GBv8HB/8ICP8JCf8KCv8LC/8MDP8NDf8ODv8PD/8QEP8REf8TE/8UFP8VFf8WFv8XF/8YGP8ZGf8aGv8cHP8dHf8eHv8fH/8gIP8hIf8iIv8jI/8kJP8lJf8pKf8qKv8rK/8sLP8tLf8uLv8vL/8wMP8yMv8zM/80NP81Nf82Nv83N/g5Of84OP86Ov87O/8+Pv8/P8FOTsBUVM5SUtdTU8l6etB0dNZzc9N/f/9BQf9DQ/9FRf9GRv9HR/9KSv9LS/9MTP9NTf9RUf9VVf9aWv9bW+l7e/9hYf94eP97e86FhdyGhtqJiduWlt+Rkduhof+Hh/+Xl/+YmP+cnOSgoOe3t+S4uOe8vOu6uu27u/Whof+hoerBwe/Q0P/d3QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAYABgAAAj/AP8JHPivUqI9cuCgCeOlECWCEAd2OvTHD5+Eash0gXKkDaeIAkEtIhToD584b9aYCdMlShEcNRBFBGVokKA+cdyYGfPFixYrTojokIHCDkRFggL1eXMGzJYrVaZEeZIEyI0YKEg0GjgJkMk3Gq1AacJEiRIkQ3pcRWEhwyWBgOTEYSMmC5QlRoYICcLXxw4bMU5YYDDnn6Q0btKI2QIlCREgPiJH5pHDBgvBDB5kkiOmjNMpSob86MGjdOkdOWgEHnxADxUtXLBIWfKYtOnTqS9bUHAAxBIoUqAwMRLEx23TqGlcpsAbwo8iSJAUETL6OI8dyVeYYE4AgY0cPCLb4baO/cYMFSUiHBiQQMYMGzdyYMd+G3tlooMJDNiQYkWMGTTEN9+AO5jHggkSKDDAAHPAUMIJ/r0Xn3z2mYcVcwMEUMAjkExAwYMRwnfDiDbMgNUI6mXIwSf/fMCABBaUoAIL/81gowyXjSCBfgEYgIdAkThAgAIRxHiCCkiqcEIJrC1IQAgE3RHAAAS8aMEIJZQwggUSMLAelRdoApEIAlCpwIsUUCBBBF4SQMABF0ACEiMaUHnAmQwwwNsBByzgwiYgCYQJHQ8ogKeeDDTgQR6BQuSJIy90UAEGLdRhSaABAQA7");
}
.fa-availability-yellow {
	background-image: url("data:image/gif;base64,R0lGODlhGAAYAPcAAAAAAJmZAJiYAZqaAJycAJ6eAKCgAKCgA6KiAKOjBKSkAKamAKenBaamCqioAKqqAKurBKysAK6uAK6uA6SkFKysEa2tFbGxALKyALS0ALa2ALe3Bbi4ALu7ALi4B7y8AL6+ALGxEbOzEbu7ELq6H6amIK+vIrGxJb6+Ib29Jru7Nrq6Ob+/O7m5U8HBAcLCAMXFAMbGAMjIAMrKAMzMAM7OANDQANLSANPTAtTUANbWANnZANvbANzcAN7eAMLCLMPDPMXFPM/PPeDgAOPjAOTkAOjoAOrqAO3tAO/vAOXlFPDwAPLyAPT0APf3APf3Afj4APj4Afr6APz8AP39Af39Av7+AP//Af//Av7+A/7+BP//Bf//Bv//B///CP//Cf//Cv//C///DP//Df//Dv//D///EP//Ef//E///FP//Ff//Fv//F///GP//Gf//Gv//HP//Hf//Hv//H///IP//If//Iv//I///JP//Jf//Kf//Kv//K///LP//Lf//Lv//L///MP//Mv//M///NP//Nf//Nv//N/j4Of//OP//Ov//O///Pv//P8DATsPDTsDAVM7OUtfXU8nJetDQdNbWc9TUff//QP//Qf//Q///Rf//Rv//R///SP//Sv//S///TP//Tf//Uf//Vf//Wv//W+npe///Yf//eP//e87Ohdvbh9raidvblt/fkd7ektvbof//h///l///mP//nOTkoOfnt+TkuOfnvOvruu3tu/X1of//oerqwe/v0P//3QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAYABgAAAj/AP8JHPiP1ilMjRYN4pNn1CyCEAfyKsVpU6ZLiwr9wbNGDKJdEQX+SiXqE6dMjBQZCsQHDxswT5SYiviLVChQmhglCuRHT546cdJ8iVLkhiSIqEB90qRI0B47cuC4YaOGzBYnRGy4WDVQlqdOmRRtjLMGzZkyZcZ4qdJkiA0QJGwJ9NSI0aE+dNaYCeOlC5e/WaYsGVIDhIRH/2IRSkSoj501ZL5syUKZ8hUpS3zU+BChAq5GfQA9dVPGixYsV1KntgIliQ8anBdYelPnzpw2ZiSjVr269esPDxSsMLOmzZozYbhk4a2adZIeNDoEt6AFzJgxYLqcZn7FyhQoSHjM/+DgAAEDJlCoUN7N3XuTIzpgYFBQoAERI0ucSJkyxYoV3v5BsUQRb0lgAAEm4LDDEEck0QQU/fknoRTv9SCDBg4QQIAjQsBAgw5D4PfgflNIAcV7PtggXQEDHNDKKxt0EEMNOxBxxBJN5NjEEkf4cMMLEiAwwAAn+PKPCg9k8EEMN/Rw3xFHGEFEDza8kIECBAyQACUCwUIBAg5gsGQNOeigQw41xNCBBFgSYAALBE0SAAEIRKDBBy/EEMMLIGgQAX10hpALRC0IMACYEmTAAQcZXBCcAQUoIIIrIalSQgEILPBABJw6sIACCkAAhC4hCXQLJBU4sCmnDzwwQQqVlBAKUS+sBIGCByP8EEktpQYEADs=");
}
.fa-availability-yellow_green {
	background-image: url("data:image/gif;base64,R0lGODlhGAAYAPcAAAAAACeaJyecJyicKCieKCqfKjyaJyigKCmiKSmkKSqlKimmKSqmKi+iKSyiLC6mLiqoKiuqKyusKyuuKyypKi2rLSysLCyuLC6uLiywLC2zLS21LS62Li64Li+7Ly+8LzKmMjisODytPDe0LjC9LzO3MzC+MDmxOTm/MT26PTDCMDHEMTLGMjLJMjPMMzPOMzXOMzTPND3ONDTQNDXUNTbXNjbYNjfbNzfdNzfeNzjfODnkOTnmOTroOjrqOjvsOzzqOzzuPDzwPDzyPD30PT/1Pj74Pj76Pj/8P0qYKFqdKUCtLEq/MVCjKlq8MWuaKGa0L3SpLEWvRUimQUiwSE26Rkm/SUy9TFm8WVy+XErGM07RNV/AMlPfOUPgOEXoOkfvPEr1Pkr4P1njOlvzPl7wPl36P2XQNmrcOXzSN2jgOnTvPnTxPlLCUl7DXl7FXkH9QEv9QEz+QF/9QV/+QWrAamvDa2/Ab3DNcGD/QmT/QnX7QXb+Qnj5QHv/Q4CiK5akO5m9MqirL6K0MLe3Nbu7M4+5bru8W4/JNZDRN4TiO4nrPY3xPp3iPJ7lPL/FNrvPOLHaOrTjPZL6QZX+Q6HWdan1QK70QK78QrD/RLX+RL/xQMXFNc3NONHUOtfYO9nZO9DoPtngPeHhPeLiPenpP+rqP9DQZMf2Qsr/Rcv/Rsz8Q87+RNDlTN/4Q+/vQeT+ROX/RuX/SO/1QvHxQfPzQvH3Q/f3Q/T9RPb/Rvb/Sfb/TPb/T/j4Q/r6RPn/Rvz8RP7+Rvn/Tv7+SP7/Sv//TP//Tv//UP//Uv7/VP//Vv//WP//Wv//XP//X/f3bP//YP//Y///ZP//Zv//aP//a///bf//bv//cP//cv//df//dv//eP//ev//fP//f4vQi4vWi5TTk57anqDOmKXbnLrJj6XfpanbqLHksd/fptncsdXpmP//g///h///iP//jP//nv//p/H1uP//s///tP//uMPkw8Pnw8fnx8fqx8vqy8ntydjv2P//5gAAACH5BAEAAP8ALAAAAAAYABgAAAj/AP8JHPjPHjxt2bBVkwatXT2CEAfae8eN27aE1aZBU1bs2byIAv3F++bNYrZr1ahJc8bRVit2Ef258+bt4jVq06JBa8Ys2S5XkhJVghjPWzduN1c2W6ZMWTJeslA5SsOl3EB63bptuzbNWU9kx4wdEyaL1SVFZ5hUwSewW7Zs1qT1PFaMmF1dslRhYqRmC4oLdv7Jq4ZSWrNkx4gNCxYsVyxWmSat6SKDhIQQ+bJNU9lMWeLFwX7BYqWJUp8yXmB8sMBA3LJmXhErZpwLVqpMlPiYAZPjxYcICbAcS+Y0LOjakCn5oSMmCA4XHiIgEDGsmDFjdReLJq1cjxwjQW60/+jA4MCDWr2AMaZtGzefPHKQEPFRY4WGBARAkCpF65YvYLiMhpsf8MFxxBA8zGDCBAcMIIUnoIxiyiuzuLJKaX54ZyARP+DQAgcQCDBAHadw0sknooSyiSWT7DFHHEcYMZ8OM0RHQAAFoKMOIYU8AkkkjSzCBhlhFEGEED7QqEIGCAQQABX9/HOIIIMEgkgaaIzxBRA+9LADDjOocJ8AATgAjkDrAPJHFFA4oYUMNNRgAw0xsOBBBgkMEMABWRBkzhNKNLHECCSowAILK3zAgQT4CYDACfpAZEgSBjRAwQUcdNDBBhkAdwABj54DEjlTOMpABBJYIAEECySgQAVu8CMDkkD33BECBBGoKkEEEWBwRTizQrTPOG9YUUIKbeCRzqwBAQA7");
}
.fa-availability-green {
	background-image: url("data:image/gif;base64,R0lGODlhGAAYAPcAAAAAAACZAAGYAQCaAACcAACeAACgAAOgAwCiAACkAACmAASjBAWnBQCoAACqAACsAACuAAOuAwSrBAqmCgCxAACyAAC0AAC2AAW3BQC4AAC7AAC8AAC+AAe4BxSkFBGsERWtFRGxERGzERC7EB+6HyCmICKvIiWxJSG+ISa9Jja7Njm6OTu/OwHBAQDCAADFAADGAADIAADKAADMAADOAADQAADSAALTAgDUAADWAADZAADbAADcAADeAADgAADjAADkAADoAADqAADtAADvAADwAADyAAD0AAD3AAH3AQD4AAH4AQD6AAD8AAH9AQL9AgD+AAH/AQL/AgP+AwT+BAX/BQb/Bgf/Bwj/CAn/CQr/Cgv/Cwz/DA3/DQ7/Dg//DxTlFBD/EBH/ERP/ExT/FBX/FRb/Fhf/Fxj/GBn/GRr/Ghz/HB3/HR7/Hh//HyzCLDzDPDzFPD3PPSD/ICH/ISL/IiP/IyT/JCX/JSn/KSr/Kiv/Kyz/LC3/LS7/Li//LzD/MDL/MjP/MzT/NDX/NTb/Njf/Nzn4OTj/ODr/Ojv/Oz7/Pj//P1O5U07ATk7DTlTAVFLOUlPXU0H/QUP/Q0X/RUb/Rkf/R0r/Skv/S0z/TE3/TVH/UVX/VVr/Wlv/W3rJenPWc3TQdH/Tf2H/YXvpe3j/eHv/e4XOhYfbh4naiZHfkZbblof/h5f/l5j/mJz/nKHboaDkoKH1oaH/obfnt7jkuLznvLrrurvtu8HqwdDv0N3/3QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAYABgAAAj/AP8JHPgPFqlJjBQJ2oOn0yuCEAfS+nTJEqWEhPzcMcPl0KyIAnmd4pTpEqVFiQoB2nPnjJYkYEpF5OVpk6ZKixAB6pMHDx02ZLIsAWJDEkRTmjJVShRIT502a9KcKeOlCpIfNVqkGugKk8lEGtmYGSPmy5cuWJ4c8VGDA4laAjExWmSIzxwzYbZguWKl75QmRXzQ4ADh0b9WgxAN4lPHjJcsVaZIlhyFSZEeNDY8+HCLEZ8/TtN8wUJFSpTTp6EoIdJjhmYFo9TQseMGTRjIplGnXt16g4MEK8KYQWNGzBYrU3SjVk2ExwwNv0FQ0dKli5YrpZVHgdJEyZAdMjI0/0DAwIgSJ5Jza+d+REiOFxUSFJjwI0gRJEyaNIECRTd/JUUA0RYEBhBgwg06+CAEEUcosR9/EDLRHg8xXNAAAQQ4IscLM+Tgg30N5tcEE0q010MN0BUwwAGsrIKBBjDQoMMPQhRxxI1HFCFEDza4AAECAwxwwi7/qOCABRvAYAMP9QkhRBBA8ECDCxYkQMAAC4giUCweINBABUnSgEMOOeBAAwwaQGAlAQawQBAoARCAwAMXbOACDDC4wMEFD8gnZwi4QNSIAAN4CcEFGWRgAQW/GVBAAiKsAhIqJRSAgAIOPKBpAwokkIAEcOQCkkC2QPJBA5lq6oADEaQQyqgQ6Q2iShwodDDCG5HIMmpAADs=");
}