/*!
 * OfficeGest
 * ©2021 Guisoft
 *
 * OG Font 1.3
 * Font Awesome 5.14.0 Pro
 * Font Awesome 4 Leagcy Support
 * FamFamFam Flags
 *
 * ATENÇÃO! Este ficheiro é o resultado de uma compilação, não deve ser editado diretamente.
 */

@import "og-font";

@import "font-awesome/all";
@import "font-awesome/v4-shims";
@import "font-awesome/extras";

@import "flags";